import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}


export function checkIfKeyHasNonNullValue(data: any, key: string|number): boolean {
    // Return false for null, undefined, non-arrays, or empty arrays
    if (!data || !Array.isArray(data) || data.length === 0) return false;

    // Check if at least one item has a non-null, non-undefined value for the key
    return data.some((item) => item[key] !== null && item[key] !== undefined);
}
